export default {
  'es.messages': require('../../../../lang/es/messages.php'),
  'es.remove_model': require('../../../../lang/es/widgets/remove_model.php'),
  'es.clone_model': require('../../../../lang/es/widgets/clone_model.php'),
  'es.custom_print_entity': require('../../../../lang/es/widgets/custom_print_entity.php'),
  'es.duplicate_model': require('../../../../lang/es/widgets/duplicate_model.php'),
  'es.forms': require('../../../../lang/es/widgets/forms.php'),
  'es.permission_component': require('../../../../lang/es/widgets/permission_component.php'),
  'es.campaign_component': require('../../../../lang/es/widgets/campaign_component.php'),
  'es.report_groupings': require('../../../../lang/es/widgets/report_groupings.php'),
  'es.cms_selector_page': require('../../../../lang/es/widgets/cms_selector_page.php'),
  'es.cms_selector_type': require('../../../../lang/es/widgets/cms_selector_type.php'),
  'es.cms_header': require('../../../../lang/es/widgets/cms_header.php'),
  'es.forms_shippings': require('../../../../lang/es/forms/crm/shippings.php'),
  'es.forms_repository': require('../../../../lang/es/forms/rep/repository.php'),
  'es.lists': require('../../../../lang/es/lists.php'),
  'es.forms_crm_customers': require('../../../../lang/es/forms/crm/customers.php'),
  'es.daterangepicker': require('../../../../lang/es/daterangepicker.php'),
  'es.statistics': require('../../../../lang/es/forms/statistics.php'),
  'es.event': require('../../../../lang/es/forms/event.php'),
  'es.forms_crm_external_register': require('../../../../lang/es/forms/crm/external_register.php'),
  'es.page': require('../../../../lang/es/forms/cms/page.php'),
  'es.conference': require('../../../../lang/es/forms/conference.php'),
  'es.custom_form': require('../../../../lang/es/forms/cms/custom_form.php'),
  'es.customer_types': require('../../../../lang/es/forms/crm/customer_types.php'),
  'es.cms_editor': require('../../../../lang/es/forms/cms_editor.php'),
  'es.filters_builder': require('../../../../lang/es/forms/filters_builder.php'),
}
