const queryString = require('query-string')

$(() => {

  let blockLoading = '#app form'

  let loading = function () {
    mApp.block(blockLoading, {})
  }

  let unLoading = function () {
    mApp.unblock(blockLoading)
  }

  $('.js-conference-export').click((ev) => {

    ev.preventDefault()
    ev.stopPropagation()

    let conferenceId = $('.js-conference-id').val()

    loading()


    const headers = { 'Contet-Type': 'application/x-www-form-urlencoded' }
    const routeData = route('conference.export', {
      conference: conferenceId,
    }, false)
    axios.post(routeData, $('#tab_generateTab :input').serialize(), { responseType: 'blob', headers }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      const filename = response.headers['content-disposition'].match(/filename=("?)((?:\\"|.)+)\1/)[2]
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      setTimeout(() => document.body.removeChild(link), 500)
    }).catch((error) => {
      console.log(error.response)
      const title = error.response.status === 404 ? window.trans.trans('conference.streaming_events.noVideomonitorableProperty') : window.trans.trans('conference.streaming_events.customer_name')
      Swal.fire({
        title
      })
    }).finally(unLoading)
  })

  $('#tab_generateTab').ready(() => {
    $('#tab_generateTab *[disabled]').attr('disabled', false)
  })

})
